import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const SouthernCaliforniaReproductiveCenterLandingPage = () => (
  <PhysicianLandingPage
    physician="Southern California Reproductive Center"
    institution="Southern California Reproductive Center"
    referralCode="SCRC"
    physicianURL="https://www.scrcivf.com/"
  />
)

export default SouthernCaliforniaReproductiveCenterLandingPage
